<template>
  <div>
<!--    <div class="flex items-center p-2 mb-2" style="font-size: 25px; ">-->
<!--      <a-icon type="arrow-left" class="mr-3"/>-->
<!--      {{$t('create_request_header')}}-->
<!--    </div>-->
<!--    <a-card >-->
<!--      <div>-->
<!--        {{title}}-->
<!--      </div>-->
<!--      <div class="w-full" >-->
<!--        <div class="w-full">-->
<!--          <div  class="w-full">-->
<!--            <a-checkbox :checked="has_an_account" @change="onChange">-->
<!--              {{ $t('the_client_already_has_an_account') }}-->
<!--            </a-checkbox>-->
<!--            <div>-->
<!--              <FormSelect v-if="has_an_account===true" :formItems="formHasAccount" ref="refFormCreateClientHasAccount" :cols="1"/>-->
<!--            </div>-->
<!--            <div >-->
<!--              <FormSelect-->
<!--                  v-if="has_an_account===false"-->
<!--                  :formItems="formItemsCreate"-->
<!--                  ref="refFormCreateClientNoAccount" :cols="1"-->
<!--                  @iin_already_exists="iin_already_exists"-->
<!--                  @number_already_exists="number_already_exists"/>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="w-full" v-if="web_socket">-->
<!--            <div ref="tabularTable"></div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <FormSelect :formItems="formItemsCreateRequest" ref="refFormCreateRequest" :cols="1" class="mb-2"/>-->
<!--      </div>-->
<!--      <a-button-->
<!--          class="default-button mr-1"-->
<!--          size="small"-->
<!--          @click="create_client_and_request">-->
<!--        Сохранить-->
<!--      </a-button>-->
<!--    </a-card>-->
</div>
</template>

<script>
import CustomDrawer from "@/crm_components/CustomDrawer/CustomDrawer";
import FormSelect from "@/components/Forms/FormAddClient/FormSelect";
import FormSelectItems from "@/pages/AddClient/ClientMixins/FormSelectItems";
import FormItemsUpdate from "@/pages/AddClient/RequestMixins/FormItemsUpdate";
import formSaveMixin from "@/crm_components/form_save/FormSaveMixin";
import CustomTabs from "@/crm_components/custom_tabs/CustomTabs";
import GoogleMap from "@/crm_components/GoogleMap/GoogleMap";
import {EventBus} from "@/configs/eventBus";
import {mapActions} from "vuex";
import TableRequest from "@/pages/AddClient/ClientAndRequest/TableRequest.vue";
import Tabulator from '@/components/CustomTabular/tabulator-master/dist/js/tabulator.js'
export default {
  name: "CreateRequest",
  components:{
    TableRequest,
    GoogleMap,
    CustomDrawer,
    FormSelect,
    CustomTabs,
  },
  props:{
    name_event_bus:{
      type:String,
      default: 'clients_event_bus'
    },
    title:{
      type:String,
      default: ''
    },
  },
  data(){
    return{
      visible:false,
      has_an_account:false,
      no_address_required:false,
      health_threat:false,
      add_request:false,
      web_socket:false,
      info_call:{}
      // tabs:[
      //   {name:this.$t('requests'), key:'requests'},
      // ],
    }
  },
  mixins:[
    FormSelectItems,
    FormItemsUpdate,
    formSaveMixin,
  ],
  async mounted() {
    // EventBus.$on('open_modal_client_and_request', this.transition_main_request);
  },
  methods:{
    ...mapActions({
      CHECK_NUMBER_V2:'client/CHECK_NUMBER_V2',
    }),
    async transition_main_request(add_request=false, data=null, web_socket=false){
      this.info_call = {}
      this.web_socket = web_socket
      if (data){
        if (data.type==='ACCEPTED'){
          this.visible = true
          this.add_request = add_request
        }
      }else{
        this.visible = true
        this.add_request = add_request
      }
      let placement = 'bottomRight'
      if (data){
        this.info_call = await data
        await this.CHECK_NUMBER_V2({ number: data.phone }).then((res)=>{
          console.log('res', res)
          if (res.exists){
            this.number_already_exists({data: res.data})
            if (data.type==='INCOMING'){
              this.$notification['info']({
                message: this.$t('incoming_call'),
                description: (
                    <div>
                      <div class="flex items-center">
                        <span class="mr-2">
                          <a-icon type="user"/>
                        </span>
                        <span>
                          {res.data.profile.full_name}
                        </span>
                      </div>
                      <div class="flex items-center">
                        <span class="mr-2">
                          <a-icon type="phone"/>
                        </span>
                        <span>
                          {res.data.number}
                        </span>
                      </div>
                    </div>
                ),
                duration: 0,
                placement,
              });
            }else if(data.type==='CANCELLED'){
              this.$notification['error']({
                message: this.$t('cancelled_call'),
                description: (
                    <div>
                      <div class="flex items-center">
                        <span class="mr-2">
                          <a-icon type="user"/>
                        </span>
                        <span>
                          {res.data.profile.full_name}
                        </span>
                      </div>
                      <div class="flex items-center">
                        <span class="mr-2">
                          <a-icon type="phone"/>
                        </span>
                        <span>
                          {res.data.number}
                        </span>
                      </div>
                    </div>
                ),
                duration: 0,
                placement,
              });
            }
          }else{
            if (data.type==='INCOMING'){
              this.$notification['info']({
                message: this.$t('incoming_call'),
                description: (
                    <div>
                      <div class="flex items-center">
                        <span class="mr-2">
                          <a-icon type="phone"/>
                        </span>
                        <span>
                          {data.phone}
                        </span>
                      </div>
                    </div>
                ),
                duration: 0,
                placement,
              });
            }else if(data.type==='CANCELLED'){
              this.$notification['error']({
                message: this.$t('cancelled_call'),
                description: (
                    <div>
                      <div class="flex items-center">
                        <span class="mr-2">
                          <a-icon type="phone"/>
                        </span>
                        <span>
                          {data.phone}
                        </span>
                      </div>
                    </div>
                ),
                duration: 0,
                placement,
              });
            }
            this.$refs.refFormCreateClientNoAccount.clientData = {}
            this.$refs.refFormCreateClientNoAccount.clientData.number = data.phone
          }
        })
      }
    },
    async create_client_and_request(){
      try {
        let refForm
        if (this.has_an_account){
          refForm = this.$refs.refFormCreateClientHasAccount
        }else{
          refForm = this.$refs.refFormCreateClientNoAccount
          if (refForm.clientData.iin === ''){
            refForm.clientData.iin = null
          }
        }
        if (this.web_socket){
          refForm.clientData['dop_requests'] = await this.tabulator.getSelectedData().map(item=>item.uid)
          refForm.clientData['info_call'] = await this.info_call
        }
        console.log('refForm', refForm)


        let valid_map = true
        let valid_request = true
        if (this.$refs.refFormCreateRequest){

          valid_request = await this.$refs.refFormCreateRequest.$refs.refValidationObserver.validate()
          console.log('this.$refs.refFormCreateRequest.clientData', this.$refs.refFormCreateRequest)
          console.log('this.$refs.refFormCreateRequest.clientData', this.$refs.refFormCreateRequest.clientData)
          console.log('await this.$refs.refFormCreateRequest.$refs.refValidationObserver', await this.$refs.refFormCreateRequest.$refs.refValidationObserver)
          refForm.clientData['request'] = await this.$refs.refFormCreateRequest.clientData
          // refForm.clientData['request']['no_address_required'] = this.no_address_required
          // refForm.clientData['request']['health_threat'] = this.health_threat

          // if (!this.no_address_required){
          //   refForm.clientData['request']['map_requests'] = await this.$refs.refMapRequest.address
          //   valid_map = await this.$refs.refMapRequest.$refs.refValidationObserver.validate()
          // }

        }
        // console.log('valid_map', valid_map)
        // console.log('this.$refs.refMapRequest.$refs.refValidationObserver', this.$refs.refMapRequest.$refs.refValidationObserver)
        // console.log('valid_request', valid_request)
        // console.log('this.$refs.refFormCreateRequest.$refs.refValidationObserver', this.$refs.refFormCreateRequest.$refs.refValidationObserver)

        let valid = valid_request && valid_map
        // console.log('valid', valid)

        // await this.handleSubmit(
        //     refForm,
        //     this.name_event_bus,
        //     'organizations/clients/',
        //     'create',
        //     null,
        //     valid
        // )
      }catch (e) {
        console.log('create_client_and_request', e)
      }


    },
    onChange(e) {
      this.has_an_account = e.target.checked;
    },
    on_add_request(e) {
      this.add_request = e.target.checked;
    },
    async number_already_exists(message){
      this.has_an_account = true
      await this.$nextTick();
      this.$refs.refFormCreateClientHasAccount.clientData = {}
      this.$refs.refFormCreateClientHasAccount.clientData['client'] = message.data.uid
      this.$refs.refFormCreateClientHasAccount.clientData['number'] = null
      console.log('message', message)
      this.tabulator = new Tabulator(this.$refs.tabularTable, {
        data: message.data.customer_request, //link data to table
        reactiveData:true, //enable data reactivity
        columns: [
          {
            headerName: "#",
            title: "#",
            use:true,
            headerSort: false,
            field: "request_number_v2",
            minWidth: 100,
            maxWidth: 100,
          },
          {
            headerName: this.$t('name'),
            title: this.$t('name'),
            headerSort: false,
            use:true,
            field: "name",
            minWidth: 150,
            cellClass: ['a-link', 'cursor-pointer'],
            open_drawer:true,
            formatter:function(cell, formatterParams){
              var value = cell.getValue();
              return "<span class='a-link cursor-pointer'>" + value + "</span>";
            },
          },
          {
            headerName: "Дата",
            title: "Дата",
            headerSort: false,
            use:true,
            field: "created",
            minWidth: 150,
          },
          {
            headerName: this.$t('status'),
            title: this.$t('status'),
            headerSort: false,
            use:true,
            field: "status_request.name",
            minWidth: 150,
          },
          {
            headerName: this.$t('result'),
            title: this.$t('result'),
            headerSort: false,
            use:true,
            field: "result_request.name",
            minWidth: 150,
          },
        ], //define table columns
        rowHeader:{headerSort:false, resizable: false, frozen:true, headerHozAlign:"center", hozAlign:"center", formatter:"rowSelection", titleFormatter:"rowSelection", cellClick:function(e, cell){
            cell.getRow().toggleSelect();
          }}
      });
      // this.$refs.refTableRequest.$refs.refCustomTabular.data = message.data.customer_request
    },
    async iin_already_exists(message){
      this.has_an_account = true
      await this.$nextTick();
      this.$refs.refFormCreateClientHasAccount.clientData['client'] = message.uid
      this.$refs.refFormCreateClientHasAccount.clientData['number'] = null
      console.log('message', message)
    },
    onChangeNoAddressRequired(e){
      this.no_address_required = e
    },
    onChangeHealthThreat(e){
      this.health_threat = e
    },
    close(){
      this.visible = false
    },
    afterCreate(val){
      val.refForm.clientData = {}
      try {
        if (this.$refs.refFormCreateClientHasAccount){
          this.$refs.refFormCreateClientHasAccount.$refs.refValidationObserver.reset()
        }
      }catch (e) {

      }
      try {
        if (this.$refs.refFormCreateClientNoAccount) {
          this.$refs.refFormCreateClientNoAccount.$refs.refValidationObserver.reset()
        }
      }catch (e) {

      }
      if (this.$refs.refFormCreateRequest){
        this.$refs.refFormCreateRequest.clientData = {}
        this.$refs.refFormCreateRequest.markers = []
        navigator.geolocation.getCurrentPosition(position => {
          this.$refs.refFormCreateRequest.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        });
        try {
          this.$refs.refFormCreateRequest.$refs.refValidationObserver.reset()
        }catch (e) {

        }

        if (this.$refs.refFormCreateRequest.$refs.refUpload){
          this.$refs.refFormCreateRequest.$refs.refUpload[0].sFileList = []
        }
      }
      this.$emit('after_create', val)
    },
    afterUpdate(){
      this.$emit('after_update', val)
    },
  }
}
</script>

<style scoped>

</style>
